import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/solid"
import { Link, navigate } from "gatsby"
import React, { Component } from "react"
import { connect } from "react-redux"
import LogoBlack from "../../images/logo_black.png"
import { updateLanguage } from "../../store/actions/utilsActions"
import { authStateInterface } from "../../store/reducers/authReducer"
import {
  Language,
  utilsStateInterface,
} from "../../store/reducers/utilsReducer"
import { localeContent } from "../../utils/locale"
import IconButton from "./IconButton"
import Select from "./Select"

interface Props {
  page: string
  authStore: authStateInterface
  utilStore: utilsStateInterface
  updateLanguage: (language: Language) => void
}

interface Navigation {
  name: string
  href: string
}

const navigation = [
  { name: "Create Appointment", href: "/createAppointment" },
  { name: "Events", href: "/events" },
  { name: "How To Recycle", href: "/recycleUCO" },
  { name: "Our Initiatives", href: "/ngoInitiative" },
  { name: "FAQs", href: "/faqs" },
]

const navigationBM = [
  { name: "Temujanji ", href: "/createAppointment" },
  { name: "Acara", href: "/events" },
  { name: "Cara Mengitar Semula", href: "/bm/recycleUCO" },
  { name: "Inisiatif Kami", href: "/bm/ngoInitiative" },
  { name: "Soalan Lazim", href: "/bm/faqs" },
]

const navigationCH = [
  { name: "申请预约", href: "/createAppointment" },
  { name: "活动", href: "/events" },
  { name: "食用油再循环", href: "/ch/recycleUCO" },
  { name: "我们的倡议", href: "/ch/ngoInitiative" },
  { name: "常见问题", href: "/ch/faqs" },
]

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ")
}

interface State {
  mobileMenu: boolean
  language: keyof typeof Language
  hideLanguage: boolean
}

class Header extends Component<Props> {
  state: State = {
    mobileMenu: false,
    language: "EN",
    hideLanguage: true,
  }

  componentDidMount() {
    const currentPage = window.location.pathname.split("/")
    let bmPage = false
    let chPage = false
    let hideLanguage = false
    const hideLanguagePage = [
      "auth",
      "referFriend",
      "referrals",
      "petronas",
      "events",
      "createAppointment",
    ]
    if (currentPage.length > 0) {
      currentPage.map(eachPath => {
        if (eachPath === "bm") {
          bmPage = true
        }
        if (eachPath === "ch") {
          chPage = true
        }
        if (hideLanguagePage.includes(eachPath)) {
          hideLanguage = true
        }
        return null
      })
    }
    if (bmPage) {
      this.props.updateLanguage(Language.BM)
      this.setState({ language: "BM", hideLanguage })
    } else if (chPage) {
      this.props.updateLanguage(Language.CH)
      this.setState({ language: "CH", hideLanguage })
    } else {
      this.props.updateLanguage(Language.EN)
      this.setState({ language: "EN", hideLanguage })
    }
  }

  handleOpenMobileMenu = () => {
    this.setState({
      mobileMenu: !this.state.mobileMenu,
    })
  }

  handleLanguageChange = (id: string, key: string) => {
    this.setState(
      {
        [id]: key,
      },
      () => {
        const currentPage = window.location.pathname.split("/")
        const pathLength = currentPage.length
        if (this.state.language === "EN") {
          if (currentPage[pathLength - 1] !== "en") {
            navigate(`/${currentPage[pathLength - 1]}`)
          } else {
            navigate("/")
          }
        } else if (this.state.language === "CH") {
          if (currentPage[pathLength - 1] !== "ch") {
            navigate(`/ch/${currentPage[pathLength - 1]}`)
          } else {
            navigate("/ch")
          }
        } else {
          if (currentPage[pathLength - 1] !== "bm") {
            navigate(`/bm/${currentPage[pathLength - 1]}`)
          } else {
            navigate("/bm")
          }
        }
      }
    )
  }

  handleLogoLink = () => {
    if (this.props.utilStore.tngAccess) {
      return "/tng"
    } else if (this.props.utilStore.language === Language.EN) {
      return "/"
    } else if (this.props.utilStore.language === Language.BM) {
      return "/bm/"
    } else {
      return "/ch/"
    }
  }

  renderLogo = () => {
    return (
      <Link to={this.handleLogoLink()}>
        <img className="h-12 w-auto sm:h-10" src={LogoBlack} alt="Arus Oil" />
      </Link>
    )
  }

  renderDashboardLink = () => {
    if (this.props.utilStore.tngAccess) return "/tngHome"
    else return "/dashboard"
  }

  renderHeaderLink = (type: string, isLaptop: boolean) => {
    let linkNav = ""
    let linkTitle = ""
    switch (type) {
      case "signIn":
        linkTitle = localeContent(this.props.utilStore.language).header.signIn
        linkNav = "/auth/signIn"
        break
    }

    if (linkNav && linkTitle) {
      if (isLaptop) {
        return (
          <Link to={linkNav}>
            <span className="ml-8 whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-900">
              {linkTitle}
            </span>
          </Link>
        )
      } else {
        return null
      }
    } else {
      return null
    }
  }

  renderLanguageOption = () => {
    return Object.keys(Language).map((eachLanguageKey, index) => {
      return {
        key: eachLanguageKey,
        title: localeContent(this.props.utilStore.language).language[index],
      }
    })
  }

  renderHeaderComponent = (localNavigation: Navigation[]) => {
    return (
      <>
        <div className="grid grid-cols-2 gap-y-4 gap-x-8">
          <div className="w-40">
            {!this.state.hideLanguage && (
              <Select
                id="language"
                placeholder="Please select language"
                value={this.state.language}
                options={this.renderLanguageOption()}
                onChange={this.handleLanguageChange}
              />
            )}
          </div>
        </div>
        {localNavigation.map((eachNavigation, index) => (
          <div className="grid grid-cols-2 gap-y-4 gap-x-8 my-4" key={index}>
            <Link
              to={eachNavigation.href}
              className="text-sm font-medium text-gray-900 hover:text-gray-700"
            >
              {eachNavigation.name}
            </Link>
          </div>
        ))}
      </>
    )
  }

  render() {
    const { language } = this.props.utilStore
    let localNavigation: any[] = []
    if (this.props.utilStore.language === Language.EN) {
      localNavigation = navigation
    } else if (this.props.utilStore.language === Language.CH) {
      localNavigation = navigationCH
    } else {
      localNavigation = navigationBM
    }
    if (this.props.utilStore.tngAccess) localNavigation = []

    return (
      <div className="relative bg-white">
        <div className="max-w-7xl mx-auto px-4 md:px-6">
          <div className="flex justify-between items-center border-b-2 border-gray-100 py-6 lg:justify-start lg:space-x-10">
            <div className="flex justify-start lg:w-0 lg:flex-1">
              <span className="sr-only">Arus Oil</span>
              {this.renderLogo()}
              <div className="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
                {localNavigation.map(item => (
                  <Link
                    key={item.name}
                    to={item.href}
                    className={classNames(
                      this.props.page === item.name
                        ? "border-arusgreen-500 text-gray-900"
                        : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                      "inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
                    )}
                  >
                    {item.name}
                  </Link>
                ))}
              </div>
            </div>

            <div className="-mr-2 -my-2 ml-2 lg:hidden">
              <IconButton
                icon={<Bars3Icon className="h-5 w-5 text-gray-400" />}
                onClick={this.handleOpenMobileMenu}
                className="rounded-md"
              />
            </div>
            <div className="hidden lg:flex items-center justify-end lg:ml-10">
              <div className="w-40">
                {!this.state.hideLanguage && (
                  <Select
                    id="language"
                    placeholder="Please select language"
                    value={this.state.language}
                    options={this.renderLanguageOption()}
                    onChange={this.handleLanguageChange}
                  />
                )}
              </div>
              {this.props.authStore.userAuth ? (
                <Link to={this.renderDashboardLink()}>
                  <span className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-arusgreen-500 hover:bg-arusgreen-600">
                    {localeContent(language).header.dashboard}
                  </span>
                </Link>
              ) : (
                <>
                  {this.renderHeaderLink("signIn", true)}
                  <Link to="/auth/signUp">
                    <span className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-arusgreen-500 hover:bg-arusgreen-600">
                      {localeContent(language).header.signUp}
                    </span>
                  </Link>
                </>
              )}
            </div>
          </div>
        </div>

        {this.state.mobileMenu && (
          <div className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right lg:hidden z-40">
            <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
              <div className="pt-5 pb-6 px-5">
                <div className="flex items-center justify-between">
                  <div>{this.renderLogo()}</div>
                  <div className="-mr-2">
                    <IconButton
                      icon={
                        <XMarkIcon className="h-5 w-5 text-arusgreen-600" />
                      }
                      onClick={this.handleOpenMobileMenu}
                    />
                  </div>
                </div>
              </div>
              <div className="py-6 px-5 space-y-6">
                {this.props.authStore.userAuth ? (
                  <div>
                    {this.renderHeaderComponent(localNavigation)}
                    <Link to={this.renderDashboardLink()}>
                      <span className="w-full flex items-center justify-center px-4 py-2 mt-4 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-arusgreen-500 hover:bg-arusgreen-600">
                        {localeContent(language).header.dashboard}
                      </span>
                    </Link>
                  </div>
                ) : (
                  <div>
                    {this.renderHeaderComponent(localNavigation)}
                    <Link to="/auth/signUp">
                      <span className="w-full flex items-center justify-center px-4 py-2 mt-4 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-arusgreen-500 hover:bg-arusgreen-600">
                        {localeContent(language).header.signUp}
                      </span>
                    </Link>
                    <p className="mt-6 text-center text-base font-medium text-gray-500">
                      {localeContent(language).header.existingCustomer}{" "}
                      <Link to="/auth/signIn">
                        <span className="text-arusgreen-600 hover:text-arusgreen-500">
                          {localeContent(language).header.signIn}
                        </span>
                      </Link>
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    authStore: state.authStore,
    utilStore: state.utilsStore,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateLanguage: (language: Language) => dispatch(updateLanguage(language)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
